import React, {useEffect, useState} from "react";
import axios from 'axios';
import {URLs} from "../../url";
import Header from "../header/header";
import {Editor} from "@tinymce/tinymce-react";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import {AppRoute, USER_ROLES} from "../../const";
import history from "../../history";

const api = createAPI();

function AddAds(props) {
    const [adsContent, setAdsContent] = useState('');
    const [adsId, setAdsId] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [categoryValue, setCategoryValue] = useState('');

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.SCROLL_CATEGORIES, { headers: authHeader() })
            .then(res => {
                console.log(res);
                setCategoryList(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const adsHandleSubmit = (evt) => {
        evt.preventDefault();
        let data;

        categoryValue === ''
            ? data = {designed_text: adsContent}
            : data = data = {designed_text: adsContent, category_id: categoryValue};

        axios.post(URLs.BASE_URL + URLs.SCROLL, data)
            .then(res => {
                let issuePath = AppRoute.ISSUES + `/` + res.data.id;
                console.log(res);
                history.push(issuePath);
                window.location.reload();

            })
            .catch(err => console.log(err));

    };

    const onAdsChange = (designed_text, editor) => {
        setAdsContent(designed_text);
        console.log(adsContent);
    };

    return (
        <div>
            <Header />
            <div className="main-page main-page--indent">
                {props.userGroupValue === USER_ROLES.corrector ?
                    <div className="no-rules">Недостаточно прав для просмотра раздела</div> :

                    <div>
                        <div className="main-page__head-wrapper">
                            <div className="main-page__add">
                                <h1 className="title__h1">Новое объявление</h1>
                            </div>
                        </div>
                        <form onSubmit={adsHandleSubmit}>
                            <Editor
                                value={adsContent}
                                apiKey="mvgi0u38hywea3m22wddt4xhr0219ufevks9xfd32h98skim"
                                init={{
                                    invalid_elements :'em,span,div,p,img,a,table,td,th,tr,header,body,h,h1,h2,h3,h4,h5',
                                    entity_encoding: 'raw',
                                    entities: '160,nbsp,38,amp,60,lt,62,gt',
                                    height: 200,
                                    menubar: false,
                                    forced_root_block : "",
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount',
                                        'textcolor colorpicker'
                                    ],
                                    toolbar:
                                        'formatselect | bold italic | \
                                        forecolor backcolor | removeformat'
                                }}
                                name="designed_text"
                                onEditorChange={onAdsChange}
                            />
                            <div className="form__new">
                                <label className="form__label">Группа</label>
                                <select value={categoryValue}
                                        onChange={evt => setCategoryValue(evt.target.value)}
                                        className="form__select form__text-input--bottom-indent"
                                        name="views">
                                    <option value="">Без категории</option>
                                    {categoryList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.title}</option>
                                    ))}
                                </select>
                            </div>
                            <input className="button" type="submit" value="Сохранить" />
                        </form>
                    </div>

                }


            </div>
        </div>
    )
}

export default AddAds;
