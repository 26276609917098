import React, {useEffect, useState} from 'react';
import Header from "../header/header";
import {queryString, URLs} from "../../url";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import {AppRoute} from "../../const";
import {Link} from "react-router-dom";
const api = createAPI();

function Report(props) {
    const [issueReport, onIssueReport] = useState([]);

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.REPORTS + props.match.params.date + queryString.TYPE_ISSUES,
            { headers: authHeader() })
            .then(res => {
                console.log(res);
                onIssueReport(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [props.match.params.date]);

    console.log(issueReport);

    return (
        <div>
            <Header />
            <div className="main-page main-page--indent">
                <div className="main-page__head-wrapper">
                    <div className="main-page__add">
                        <h1 className="title__h1">Вышедшие объявления на: {props.match.params.date}</h1>
                    </div>
                </div>
                <div>
                    <Link className="button button__link profile__button profile__button--mb" to={AppRoute.REPORTS}>
                        Сформировать другой отчет
                    </Link>
                    <Link className="button ml-20 button__link profile__button profile__button--mb" to={`${AppRoute.REPORTS}/${props.match.params.date}&type=blocks`}>
                        Блоки за этот день
                    </Link>
                </div>

                <div>
                    <ol className="reports__list">
                        {issueReport.map((item, index) => <li className="reports__item" key={index}>
                            <div className="reports__item-wrapper">
                                <div className="reports__item-ads ellipsis">{item.text_plain}</div>
                                <div className="reports__item-amount">Количество выходов в эфир: {item.amount}</div>
                            </div>

                            </li>)}
                    </ol>
                </div>
            </div>

        </div>
    );
}

export default Report;
