import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Header from "../header/header.jsx";
import {Link} from "react-router-dom";
import {AppRoute, USER_ROLES} from "../../const";
import {queryString, URLs} from "../../url";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import history from "../../history";
import Highlighter from "react-highlight-words";
const api = createAPI();


function Main(props) {

    const {textToHighlight} = props;
    const ads = props.adsData;
    const [searchData, setSearchData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isSearched, setIsSearched] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        props.setPageNumber(selectedPage + 1);
        props.setPageString(`&page=`)
    };

    const handleDeleteClick = (e, id) => {
        e.preventDefault();
        axios
            .delete(URLs.BASE_URL + URLs.SCROLL, {params: {id: id}})
            .then((res) => {
                console.log(res);
                props.onDelete();
            })
            .catch(err => console.log(err));
    };

    const getSearchData = (e) => {
        if (e.key === 'Enter') {
            if (searchValue === '') {
                return
            }

            console.log(searchValue);

            api.get(URLs.BASE_URL + URLs.SCROLL + `?search=${searchValue}`, { headers: authHeader() })
                .then(res => {
                    console.log(res);
                    setSearchData(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
            setIsSearched(true);
        }
    };

    const refreshSearchResult = (e) => {
        e.preventDefault();
        setIsSearched(false);
        history.go(0);
    };

    return (

        <React.Fragment>
            <Header />
            <div className="main-page main-page--indent">


                <div className="main-page__head-wrapper">
                    <div className="main-page__add">
                        <h1 className="title__h1">Объявления</h1>

                        {props.userGroupValue === USER_ROLES.corrector ? '' :
                            <Link to={`${AppRoute.ADD_ADS}`} className="button button__add" type="button">Добавить</Link>
                        }
                    </div>
                    <div className="form__search-wrapper">
                        <input
                            className="form__text-input form__search"
                            id="search"
                            minLength={1}
                            placeholder="Начните вводить текст"
                            type="text"
                            onKeyDown={getSearchData}
                            // value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </div>
                </div>

                <div className="pagination--indent">
                    <div className="pagination">

                        <ReactPaginate
                            previousLabel={"Назад"}
                            nextLabel={"Вперед"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={props.paginateData.last_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                    </div>

                </div>
                <div>
                    <h4 className="title__h4">Показать объявления за:</h4>
                    <ul className="form__radio-list">
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="today"
                                type="radio"
                                value={queryString.TODAY}
                                name="filter"
                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="today">День</label>
                        </li>
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="week"
                                type="radio"
                                value={queryString.WEEK}
                                name="filter"
                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="week">Неделя</label>
                        </li>
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="month"
                                type="radio"
                                value={queryString.MONTH}
                                name="filter"
                                defaultChecked

                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="month">Месяц</label>
                        </li>
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="half_year"
                                type="radio"
                                name="filter"
                                value={queryString.HALF_YEAR}
                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="half_year">6 месяцев</label>
                        </li>
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="year"
                                type="radio"
                                name="filter"
                                value={queryString.YEAR}
                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="year">Год</label>
                        </li>
                        <li className="form__radio-item">
                            <input
                                className="form__radio"
                                id="alltime"
                                type="radio"
                                name="filter"
                                value={queryString.ALL_TIME}
                                onChange={evt => props.setRadioValue(evt.target.value)}
                            />
                            <label className="form__custom-radio" htmlFor="alltime">Показать все</label>
                        </li>
                    </ul>

                </div>
                {searchData.length === 0 ? "" :
                    <div className="title__h3--mt">
                        <button onClick={refreshSearchResult} className="button">Сбросить результаты поиска</button>
                    </div>
                }
                {searchData.length === 0 && isSearched === true ?
                    <div className="no-rules no-rules--mt20">Ничего не найдено. Попробуйте ввести запрос точнее!</div> :
                    ''
                }
                <div className="tables__wrapper">
                    <table className="tables__main">
                        <thead>
                        <tr>
                            <th className="tables__main-th">Дата</th>
                            <th className="tables__main-th">Текст</th>
                            <th className="tables__main-th">Группа</th>

                            <th className="tables__main-th"></th>
                            <th className="tables__main-th"></th>
                            <th className="tables__main-th">Проверено</th>
                        </tr>

                        </thead>

                        <tbody className="tables__body">
                        {searchData.length === 0 ?

                                    ads.map((ads) => (
                                        <tr key={ads.id} className="tables__row">
                                            <td className="tables__data">{ads.create_date}</td>
                                            <td className="tables__data">{ads.text_plain}</td>
                                            <td className="tables__data">
                                                {(ads.category === null ? '' : ads.category.title)}
                                            </td>
                                            <td className="tables__data">
                                                {props.userGroupValue === (USER_ROLES.corrector) ?
                                                 '' :
                                                    <Link
                                                        to={`${AppRoute.ISSUES}/${ads.id}`}
                                                    >
                                                        Выпуски
                                                    </Link>
                                                }

                                            </td>
                                            <td className="tables__data">
                                                {props.userGroupValue === (USER_ROLES.administrator) ?
                                                    <a href="" onClick={(e) =>
                                                        window.confirm("Вы действительно хотите удалить объявление?") &&
                                                        handleDeleteClick(e, ads.id)} className="tables__link">удалить</a> : ''}
                                            </td>

                                            <td className="tables__data text-align-center">
                                                {(ads.is_corrected === 1 ?
                                                    <img className="icon_correct" src="img/checked.svg" alt=""/>
                                                    :
                                                    <img src="img/dont.svg" className="icon_correct" alt=""/>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                :
                                    searchData.map((ads) => (
                                        <tr key={ads.id} className="tables__row">
                                            <td className="tables__data"></td>

                                            <td className="tables__data">
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={[searchValue]}
                                                    autoEscape={true}
                                                    textToHighlight={ads.scroll_text}
                                                />
                                            </td>
                                            <td className="tables__data"></td>

                                            <td className="tables__data">
                                                <Link
                                                    to={`${AppRoute.ISSUES}/${ads.scroll_id}`}
                                                >
                                                    Выпуски
                                                </Link>

                                            </td>
                                            <td className="tables__data">
                                                {props.userGroupValue === (USER_ROLES.administrator) ?
                                                    <a href="" onClick={(e) =>
                                                        handleDeleteClick(e, ads.id)} className="tables__link">удалить</a> : ''}
                                            </td>
                                            <td className="tables__data"><a href="" className="tables__link">ОК</a></td>
                                        </tr>
                                    ))
                        }

                        </tbody>
                    </table>
                </div>

            </div>
        </React.Fragment>
    )
}

Main.propTypes = {
    adsData: PropTypes.arrayOf(
        PropTypes.shape({
            create_date: PropTypes.string.isRequired,
            designed_text: PropTypes.string.isRequired,
        })
    )
};


export default Main;
