import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Header from "../header/header.jsx";
import {Link} from "react-router-dom";
import {AppRoute, USER_ROLES} from "../../const";
import {queryString, URLs} from "../../url";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import history from "../../history";
import Highlighter from "react-highlight-words";
const api = createAPI();


function Corrects(props) {
    const ads = props.adsData;
    const [adsToCorrects, setAdsToCorrect] = useState([]);


    const fetchData = () => {
        api.get(URLs.BASE_URL + URLs.CORRECTOR, { headers: authHeader() })
            .then(res => {
                console.log(res);
                setAdsToCorrect(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };


    const handleIsCorrectDone = (e, designed_text, id) => {
        let data = {is_corrected: 1, designed_text: designed_text, id: id};

        e.preventDefault();
        axios
            .put(URLs.BASE_URL + URLs.SCROLL, data)
            .then((res) => {
                console.log(res);
                fetchData();
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (

        <React.Fragment>
            <Header />
            <div className="main-page main-page--indent">
                {props.userGroupValue === USER_ROLES.administrator || props.userGroupValue === USER_ROLES.corrector
                    ?
                    <div>
                        <div className="main-page__head-wrapper">
                            <div className="main-page__add">
                                <h1 className="title__h1">Объявления на проверку</h1>
                            </div>
                        </div>
                        <div className="tables__wrapper">
                            <table className="tables__main">
                                <thead>
                                <tr>
                                    <th className="tables__main-th">Дата</th>
                                    <th className="tables__main-th">Текст</th>

                                    <th className="tables__main-th"></th>
                                    <th className="tables__main-th"></th>
                                </tr>

                                </thead>

                                <tbody className="tables__body">
                                {adsToCorrects.map((ads) => (
                                    <tr key={ads.id} className="tables__row">
                                        <td className="tables__data">{ads.create_date}</td>
                                        <td className="tables__data">{ads.text_plain}</td>
                                        <td className="tables__data">
                                            <Link className="issues__edit tables__link" to={`${AppRoute.ROOT}${ads.id}/edit`}>Редактировать</Link>
                                        </td>
                                        <td className="tables__data">
                                            <a href="" onClick={(e) =>
                                                handleIsCorrectDone(e, ads.designed_text, ads.id)} className="tables__link">Проверен</a>
                                        </td>
                                    </tr>
                                ))}


                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className="no-rules">Недостаточно прав для просмотра раздела</div>


                }

            </div>
        </React.Fragment>
    )
}


export default Corrects;
