import React, {useEffect, useState} from "react";

import Main from "../main/main.jsx";
import history from "../../history";
import {AppRoute, USER_ROLES} from "../../const";
import {createAPI} from "../../api";
import {Switch, Route, BrowserRouter, Redirect} from "react-router-dom";

import Corrects from "../corrects/corrects.jsx";
import AddAds from "../add-ads/add-ads.jsx";
import AuthScreen from "../auth-screen/auth-screen.jsx";
import Issues from "../issues/issues.jsx";
import EditAds from "../edit-ads/edit-ads.jsx";
import Queue from "../queue/queue";
import Reports from "../reports/reports";
import Report from "../report/report";
import ReportBlock from "../report-block/report-block";
import {queryString, URLs} from "../../url";
import authHeader from "../../services/auth-header";
import sidebar from "../sidebar/sidebar.jsx";
import Header from "../header/header";
import { slide as Menu } from 'react-burger-menu';
import {Link, NavLink} from "react-router-dom"
import Sidebar from "../sidebar/sidebar";
const api = createAPI();


function App(props) {

    const [adsData, setAdsData] = useState([]);
    const [paginateData, setPaginateData] = useState([]);
    const [radioValue, setRadioValue] = useState(queryString.MONTH);
    const [pageString, setPageString] = useState(``);
    const [pageNumber, setPageNumber] = useState(``);
    const [userName, getUserName] = useState(``);
    const [userGroup, getUserGroup] = useState(``);

    const fetchData = () => {
        api.get(URLs.BASE_URL + URLs.SCROLL_INTERVAL + radioValue + pageString + pageNumber, { headers: authHeader() })
            .then(res => {
                console.log(res);
                setAdsData(res.data.data);
                setPaginateData(res.data.pagination);
            })
            .catch(err => {
                console.log(err);
            })
    };


    const onDelete = () => {
        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, [radioValue, pageString, pageNumber]);

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.USER_INFO, { headers: authHeader() })
            .then(res => {
                console.log(res);
                getUserName(res.data.username);
                getUserGroup(res.data.groups[0]);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const userGroupValue = Object.values(userGroup).join();

    return (
            <div>
                <BrowserRouter history={history}>
                    <Switch>
                        <Route exact path={AppRoute.ROOT} render={(props) => <Issues {...props} />}>
                            {userGroupValue === USER_ROLES.corrector ?
                                <Redirect to={`${AppRoute.CORRECTS}`} /> :
                                <Main
                                    userGroupValue={userGroupValue}
                                    onDelete={onDelete}
                                    adsData={adsData}
                                    setRadioValue={setRadioValue}
                                    pageString={pageString}
                                    setPageString={setPageString}
                                    setPageNumber={setPageNumber}
                                    paginateData={paginateData}
                                />
                            }

                        </Route>
                        <Route exact path={AppRoute.CORRECTS}>
                            <Corrects
                                userGroupValue={userGroupValue}
                            />
                        </Route>
                        <Route exact path={AppRoute.ADD_ADS}>
                            <AddAds
                                userGroupValue={userGroupValue}
                            />
                        </Route>
                        <Route exact path={AppRoute.QUEUE}>
                            <Queue
                                userGroupValue={userGroupValue}
                            />
                        </Route>
                        <Route exact path={AppRoute.AUTH}>
                            <AuthScreen
                                userGroupValue={userGroupValue}
                            />
                        </Route>
                        <Route exact path={`${AppRoute.ISSUES}/:id?`} render={(props) => <Issues
                            {...props}
                            adsData={adsData}
                            setRadioValue={setRadioValue}
                            userGroupValue={userGroupValue}
                            pageString={pageString}
                            setPageString={setPageString}
                            setPageNumber={setPageNumber}
                            paginateData={paginateData}
                        />}/>
                        <Route exact path={`${AppRoute.ROOT}:id?/edit`} render={(props) => <EditAds
                            {...props}
                            userGroupValue={userGroupValue}
                            adsData={adsData}
                            setRadioValue={setRadioValue}
                            pageString={pageString}
                            setPageString={setPageString}
                            setPageNumber={setPageNumber}
                            paginateData={paginateData}
                        />}/>
                        <Route exact path={AppRoute.REPORTS}>
                            <Reports
                                userGroupValue={userGroupValue}
                            />
                        </Route>
                        <Route exact path={`${AppRoute.REPORTS}/:date?&type=blocks`} render={(props) => <ReportBlock
                            {...props}
                        />}/>
                        <Route exact path={`${AppRoute.REPORTS}/:date?`} render={(props) => <Report
                            {...props}
                        />}/>
                    </Switch>
                </BrowserRouter>
            </div>
        )
}

App.propTypes = {};

export default App;
