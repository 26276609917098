import React, { useState } from 'react';
import Header from "../header/header";
import 'react-calendar/dist/Calendar.css';
import {AppRoute, USER_ROLES} from "../../const";
import DatePicker from "react-date-picker";
import moment from "moment";
import {Link} from "react-router-dom";

function Reports(props) {
    const [value, onChange] = useState(new Date());
    return (
        <div>
            <Header />
            <div className="main-page main-page--indent">


                <div>
                    {props.userGroupValue === USER_ROLES.corrector
                        ? <div className="no-rules">Недостаточно прав для просмотра раздела</div>
                        :
                    <div>
                        <div className="main-page__head-wrapper">
                            <div className="main-page__add">
                                <h1 className="title__h1">Отчеты</h1>
                            </div>
                        </div>
                        <h2>Выберите дату для формирования отчета</h2>
                        <DatePicker
                            onChange={onChange}
                            value={value}
                            locale={"ru-RU"}
                        />
                        <div className="title__h3--mt">
                            <span className="title__h3">Показать отчет за </span>
                            <Link className="button button__link profile__button profile__button--mb"
                                  to={`${AppRoute.REPORTS}/${moment(value).format('YYYY-MM-DD')}`}>{moment(value).format('YYYY-MM-DD')}
                            </Link>
                        </div>
                    </div>}
                </div>

            </div>

        </div>
    );
}

export default Reports;
