import axios from "axios";
import {URLs} from "./url";
import {Redirect} from 'react-router-dom';
import {AppRoute} from './const'
import React from "react";

export const createAPI = () => {
    const api = axios.create({
        baseURL: URLs.BASE_URL + URLs.SCROLL,
        timeout: 1000,
        withCredentials: true,

        headers : {
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : 'JWT <token>'
        }
    });


    const onSuccess = (responce) => {
        return responce;
    };

    const onError = (err) => {
        const {response} = err;

        if (response && response.status === 401) {
            localStorage.removeItem("user");
            throw err;
        }
        return <Redirect to={`${AppRoute.AUTH}`} />;

        throw err;
    };

    api.interceptors.response.use(onSuccess, onError);
    return api;
};
