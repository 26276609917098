import React, {useEffect, useState} from "react";
import axios from 'axios';
import {URLs} from "../../url";
import authHeader from "../../services/auth-header";

const BLOCK_TIME = 5;

function Block(props) {
    const [blockValue, setBlockValue] = useState(BLOCK_TIME);

    const blockHandleSubmit = (evt) => {
        evt.preventDefault();
        axios.post(URLs.BASE_URL + URLs.BLOCK_TIME + blockValue)
            .then(res => console.log(res))
            .catch(err => console.log(err));
    };

    useEffect(() => {
        function getBlock() {
            axios.get(URLs.BASE_URL + URLs.BLOCK, { headers: authHeader() })
                .then(res => {
                    console.log(res);
                    props.getBlockDate(res.data);
                })
                .catch(err => {
                    console.log(err);
                })
        }
        getBlock();
        const interval = setInterval(() => getBlock(), 5000);
        return () => {
            clearInterval(interval);
        }

    }, []);


    return (
        <div>
            <form className="form__submit-block" onSubmit={blockHandleSubmit}>
                <label className="form__label form__label--margin-0" htmlFor="block">Размер блока (в минутах)</label>
                <input id="block" className="form__text-input form__text-input--mr" value={blockValue} onChange={evt => setBlockValue(+evt.target.value)} type="number"/>
                <button className="button" type="submit">Отправить</button>
            </form>
        </div>
    )
}

export default Block;
