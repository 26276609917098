export const URLs = {
    BASE_URL: `https://new-scroll.telemiks.tv/api/v1/`,
    BLOCK: `block/`,
    BLOCK_TIME: `block/?time=`,
    CORRECTOR: `corrector`,
    ISSUE: `issue/`,
    ISSUES: `/issues/`,
    REPORTS: `reports/?date=`,
    SCROLL: `scroll/`,
    SCROLL_CATEGORIES: `scroll_categories/`,
    SCROLL_INTERVAL: `scroll/?interval`,
    SCROLL_ID: `?scroll_id=`,
    USER_INFO: `user_info/`
};

export const queryString = {
    ALL_TIME: `=alltime&`,
    TODAY: `=today&`,
    WEEK: `=week&`,
    MONTH: `=month&`,
    HALF_YEAR: `=half_year&`,
    YEAR: `=year&`,

    TYPE_ISSUES: `&type=issues`,
    TYPE_BLOCK: `&type=blocks`,

    ID: `?id=`
};
