import React from "react";
import Header from "../header/header.jsx";
import {Link} from "react-router-dom";
import {AppRoute, URLs, USER_ROLES} from "../../const";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import axios from "axios";
import moment from "moment";
import 'moment/locale/ru';
import MomentLocaleUtils from 'react-day-picker/moment';
import authHeader from "../../services/auth-header";
import history from "../../history";

const regex = /<(.|\n)*?>/g;
const MONTH_CTY = 4;

class Issues extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            adsDataConent: '',
            adsIssue: [],
            fullIssue: [],
            locale: 'ru',
            adsText: '',
            content: '',
            selectedDays: []
        };

        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleWeekClick = this.handleWeekClick.bind(this);
        this.getDates = this.getDates.bind(this);
        this.getId = this.getId.bind(this);
        this.getTextById = this.getTextById.bind(this);

    }

    getDates() {
        axios.get(URLs.baseUrl + URLs.issue + URLs.scrollId + this.props.match.params.id)
            .then(res => {return res;})
            .then((result) => {this.setState({
                adsIssue: result.data.map((issue) => (new Date(issue.stamp))),
            });
            })
            .catch(err => {return err})
    }

    getId() {
        axios.get(URLs.baseUrl + URLs.issue + URLs.scrollId + this.props.match.params.id)
            .then(res => {return res;})
            .then((result) => {this.setState({
                fullIssue: result.data,
            });
            })
            .catch(err => {return err})
    }

    getTextById() {
        axios.get(`https://new-scroll.telemiks.tv/api/v1/scroll/?id=${this.props.match.params.id}`, { headers: authHeader() })
            .then(res => {return res;})
            .then((result) => {this.setState({
                content: result.data.data.map((text) => ((text.designed_text.replace(regex, '')))),
            });
            })
            .catch(err => {return err})
    }


    componentDidMount() {
        this.getDates();
        this.getId();
        this.getTextById();
    }

    handleWeekClick = (week, days, e) => {
        const { adsIssue } = this.state;

        for (let i = 0; i < 7; i++) {
            const data = {
                scroll_id: this.props.match.params.id,
                stamp: moment(days[i]).format('YYYY-MM-DD'),
            };


            const removeId = this.state.fullIssue.find(x => moment(x.stamp).format('YYYY-MM-DD') === data.stamp);
            if (!removeId) {
                adsIssue.push(days[i]);
                axios
                    .post(URLs.baseUrl + URLs.issue + URLs.scrollId, data)
                    .then(res => console.log(res))
                    .catch(err => console.log(err));
                this.getId();
            } else {

                const selectedIndex = adsIssue.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, days[i])
                );
                adsIssue.splice(selectedIndex, days);

                axios
                    .delete(URLs.baseUrl + URLs.issue, {params: {id: removeId.id}})
                    .then(res => console.log(res))
                    .catch(err => console.log(err));
                this.getDates();
                this.setState({ adsIssue });

            }

        }

    };

    handleDayClick(day, { selected }) {
        const { adsIssue } = this.state;

        const data = {
            scroll_id: this.props.match.params.id,
            stamp: moment(day).format('YYYY-MM-DD'),
        };


        if (selected) {

            const selectedIndex = adsIssue.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            adsIssue.splice(selectedIndex, 1);

            const removeId = this.state.fullIssue.find(x => moment(x.stamp).format('YYYY-MM-DD') === data.stamp);

            if (!removeId)
            {
                return;
            }

            axios
                .delete(URLs.baseUrl + URLs.issue, {params: {id: removeId.id}})
                .then(res => console.log(res))
                .catch(err => console.log(err));


        } else {
            console.log(day);
            adsIssue.push(day);
            axios
                .post(URLs.baseUrl + URLs.issue + URLs.scrollId, data)
                .then(res => console.log(res))
                .catch(err => console.log(err));

            this.getId();

        }
        this.setState({ adsIssue });

    }


    render() {

        const resultContent = this.state.content;
        resultContent.toString();

        return (
            <React.Fragment>
                <Header />
                <div className="main-page main-page--indent">

                    <div className="main-page__head-wrapper">
                        <div className="main-page__add">
                            <h1 className="title__h1">Выпуски

                            </h1>

                        </div>
                    </div>
                    <div>
                        <Link className="button button__link profile__button profile__button--mb" to={AppRoute.ROOT}>
                            Вернуться на главную
                        </Link>
                    </div>

                    <div>
                        <div className="issues__ads">
                            <div className="">{resultContent.toString()}</div>
                            <Link className="issues__edit tables__link" to={`${AppRoute.ROOT}${this.props.match.params.id}/edit`}>Редактировать</Link>
                        </div>
                    </div>
                    <div>
                        {this.props.userGroupValue === (USER_ROLES.corrector) ? <div></div> :
                        <DayPicker
                            localeUtils={MomentLocaleUtils}
                            locale={this.state.locale}
                            selectedDays={this.state.adsIssue}
                            onDayClick={this.handleDayClick}
                            numberOfMonths={MONTH_CTY}
                            showWeekNumbers
                            onWeekClick={this.handleWeekClick}

                        />}

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Issues;
