import React from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import history from "../../history";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class AuthScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.showHide = this.showHide.bind(this);


        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            type: 'password',
            score: 'null'
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    showHide(e){
        e.preventDefault();
        //e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        })
    }


    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    history.push('/');
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="login login--indent">
                     <img className="login__logo" src="img/logo.svg" width="198" height="53" alt="Телемикс" />
                    <h2 className="login__title">Войти в аккаунт</h2>
                    <p className="login__description">Получите доступ к внутреннему порталу, используя свой
                        логин и пароль.</p>
                    <Form onSubmit={this.handleLogin}
                          ref={c => {
                              this.form = c;
                          }}
                          className="login__form">
                        <label className="login__label" htmlFor="username">Логин</label>
                        <Input className="form__text-input login__input"
                               id="username"
                               name="username"
                               value={this.state.username}
                               onChange={this.onChangeUsername}
                               validations={[required]}
                               type="text"
                               placeholder="Введите логин"
                        />
                        <label className="login__label" htmlFor="password">Пароль</label>
                        <div className="login__pass-wrapper">
                            <a className="login__view-pass" onClick={this.showHide} href=""></a>
                            <Input className="form__text-input login__input" id="password" required
                                   type={this.state.type}
                                   name="password"
                                   value={this.state.password}
                                   onChange={this.onChangePassword}
                                   validations={[required]}
                                   placeholder="Введите пароль"/>
                        </div>
                        <button className="button"
                                disabled={this.state.loading}

                                type="submit">Войти</button>

                        {this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}

                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />

                    </Form>

                </div>
            </React.Fragment>
        );
    }
}

export default AuthScreen;
