export const AppRoute = {
    ROOT: `/`,
    ADD_ADS: `/add-ads`,
    AUTH: `/login`,
    ISSUES: `/issues`,
    QUEUE:  `/queue`,
    REPORTS: `/reports`,
    CORRECTS: `/corrects`,
};

export const URLs = {
  baseUrl: `https://new-scroll.telemiks.tv/api/v1/`,
  issue: `issue/`,
  scrollId: `?scroll_id=`
};

export const WEEKDAYS_SHORT = {
    ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};

export const WEEKDAYS_LONG = {
    ru: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
    ],
};

export const MONTHS = {
    ru: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
};

export const FIRST_DAY_OF_WEEK = {
    ru: 1,
};

export const LABELS = {
    ru: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
};

export const USER_ROLES = {
    administrator: `Администратор`,
    manager: `Менеджер`,
    issuing: `Выпуск`,
    corrector: `Корректор`,
};
