import React, {useEffect, useState} from "react";
import Header from "../header/header.jsx";
import axios from "axios";
import {Editor} from "@tinymce/tinymce-react";
import history from "../../history";
import {queryString, URLs} from "../../url";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import {AppRoute, USER_ROLES} from "../../const";
import { Redirect } from "react-router-dom";

// import {AppRoute} from "../../const";
//
const api = createAPI();

function EditAds(props) {

    const idData = (parseInt(props.match.params.id));
    const result = props.adsData.filter(ads => ads.id === idData);
    const groupValue = result.map((ads) => (ads.category_id));
    const [categoryList, setCategoryList] = useState([]);
    const [selectedValue, setSelectedValue] = useState(groupValue.join());
    const [designedContent, setDesignedContent] = useState('');
    const [adsContent, setAdsContent] = useState(designedContent);
    const [isCorrected, setIsCorrected] = useState(false);

    const [isSending, setIsSending] = useState(false);


    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.SCROLL_CATEGORIES, { headers: authHeader() })
            .then(res => {
                console.log(res);
                setCategoryList(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.SCROLL + queryString.ID + props.match.params.id, { headers: authHeader() })
            .then(res => {
                console.log(res);
                setDesignedContent(res.data.data.map((text) => ((text.designed_text))).join());
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    console.log(designedContent);

    const onAdsChange = (designed_text, editor) => {
        setDesignedContent(designed_text);
    };

    const handleIsCorrectChange = () => {
        setIsCorrected(!isCorrected);
    };


    const adsHandleSubmit = (evt) => {
        let data;
        let checkValue = isCorrected === true ? 1 : 0;

        selectedValue === ''
            ? data = {designed_text: designedContent, is_corrected: checkValue, id: props.match.params.id}
            : data = {designed_text: designedContent, is_corrected: checkValue, category_id: selectedValue, id: props.match.params.id};
        console.log(data);

        evt.preventDefault();
            //
            // props.userGroupValue === USER_ROLES.corrector ?
            //     history.push(AppRoute.CORRECTS)
            //     :
            //     history.push(URLs.ISSUES + props.match.params.id);
            //
            // history.go();


        axios
            .put(URLs.BASE_URL + URLs.SCROLL, data)
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    setIsSending(true);
                }
            })
            .catch(err => console.log(err));
    };

    if (isSending) return <Redirect to={URLs.ISSUES + props.match.params.id} />;
    if (props.userGroupValue === USER_ROLES.corrector && isSending) return <Redirect to={AppRoute.CORRECTS} />;

    return (
        <React.Fragment>
            <Header />


            <div className="main-page main-page--indent">

                <div className="main-page__head-wrapper">
                    <div className="main-page__add">
                        <h1 className="title__h1">Редактировать объявление</h1>
                    </div>
                </div>

                <div>


                    <form onSubmit={adsHandleSubmit}>
                        <Editor
                            value={designedContent}
                            apiKey="mvgi0u38hywea3m22wddt4xhr0219ufevks9xfd32h98skim"

                            init={{
                                invalid_elements :'em,span,div,p,img,a,table,td,th,tr,header,body,h,h1,h2,h3,h4,h5',
                                entity_encoding: 'raw',
                                entities: '160,nbsp,38,amp,60,lt,62,gt',
                                height: 200,
                                menubar: false,
                                forced_root_block : "",
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount',
                                    'textcolor colorpicker'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic | \
                                    forecolor backcolor | removeformat'
                            }}
                            name="designed_text"
                            onEditorChange={onAdsChange}

                        />

                        {props.userGroupValue === USER_ROLES.corrector ? '' :
                            <div className="form__new">
                                <label className="form__label">Группа</label>

                                <select
                                    value={selectedValue}
                                    onChange={evt => setSelectedValue(evt.target.value)}
                                    className="form__select form__text-input--bottom-indent"
                                    name="views">
                                    <option value="">Без категории</option>
                                    {categoryList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.title}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <input className="button" type="submit" value="Сохранить" />

                        <div className="form__new">
                            {props.userGroupValue === USER_ROLES.administrator || props.userGroupValue === USER_ROLES.corrector ?
                                <div>
                                    <input
                                        className="form__checkbox"
                                        id="is-correct"
                                        type="checkbox"
                                        onClick={handleIsCorrectChange}
                                    />
                                    <label className="form__custom-checkbox"  htmlFor="is-correct">Текст проверен</label>
                                </div>

                               : ''}

                        </div>
                    </form>


                </div>

            </div>

        </React.Fragment>
    )

}

export default EditAds;
