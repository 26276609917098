import React from "react";
import AuthService from "../../services/auth.service";
import {Link} from "react-router-dom"
import history from "../../history";
import Sidebar from "../sidebar/sidebar.jsx"

class Header extends React.PureComponent{
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            currentUser: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
            });
        } else {
            history.push('/login');
            history.go('/login');
        }
    }

    logOut() {
        AuthService.logout();
    }

    render() {
        const {currentUser} = this.state;

        return (
            <React.Fragment>

                <div className="header">


                    <div className="header__bar">
                        <Link to={"/"} className="nav-link">
                            <img src="/img/logo-inner.svg" width="170" height="48" className="header__logo" alt=""/>
                        </Link>
                        <a className="nav__open"  href=""></a>
                        <div className="header__user-wrapper">
                            <a href="" className="header__user"></a>
                            <div className="header__user-info">
                                <span className="header__user-post header__user-post--in-bar"></span>


                                {currentUser ? (
                                    <div>
                                        <Link to={"/profile"} className="nav-link">
                                            {currentUser.username}
                                        </Link>
                                        <a href="/login" className="nav-link" onClick={this.logOut}>
                                            <svg className="header__bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M255.15 468.625H63.787c-11.737 0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262 21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85 255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619 63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262 0-11.758-9.504-21.262-21.262-21.262z"/><path d="M505.664 240.861L376.388 113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041 14.926-6.124l129.276-127.575A21.303 21.303 0 00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"/></svg>
                                        </a>
                                    </div> ) : (
                                    <div className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link to={"/login"} className="nav-link">
                                                Login
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to={"/register"} className="nav-link">
                                                Sign Up
                                            </Link>
                                        </li>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    <nav className="nav">
                        <ul className="nav__list">
                            <li className="nav__item">
                                <a href="" className="nav__link">Simple News</a>
                            </li>
                            <li className="nav__item">
                                <a href="" className="nav__link">Рекламный портал</a>
                            </li>
                            <li className="nav__item">
                                <a href="" className="nav__link">Выпуск рекламы</a>
                            </li>
                            <li className="nav__item">
                                <a href="" className="nav__link nav__link--active">Бегучка</a>
                            </li>
                        </ul>
                    </nav>

                    <nav className="mainnav visually-hidden">
                        <h4 className="mainnav__title">Меню пользователя</h4>
                        <ul className="mainnav__list">
                            <li className="mainnav__item">
                                <a href="" className="mainnav__link">
                                    <svg className="mainnav__icon mainnav__icon--active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M199.9 359.9h68v40h-68v-40zm-74.9-5c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zm276.9-122h-202v40h201.9v-40zm-276.9-5c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zM401.9 113h-202v40h201.9v-40zM125 108c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zM40 451.9V60c0-11 9-20 20-20h391.9c11 0 20 9 20 20v225.9h40V60c0-33.1-26.9-60-60-60H60C26.9 0 0 26.9 0 60v391.9c0 33.1 26.9 60 60 60h230.9v-40H60c-11 0-20-9-20-20zM483.7 512l-39.8-39.8c-13.5 8.1-29.2 12.7-46 12.7-49.6 0-90-40.4-90-90s40.4-90 90-90 90 40.4 90 90c0 18.4-5.5 35.4-15 49.7l39.1 39.1-28.3 28.3zm-85.8-67.1c27.6 0 50-22.4 50-50s-22.4-50-50-50-50 22.4-50 50 22.4 50 50 50z"/>
                                    </svg>
                                    Заявки / бартер
                                </a>
                            </li>
                            <li className="mainnav__item">
                                <a className="mainnav__link" href="">
                                    <svg className="mainnav__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M436.9 330.8c-27.9-27.9-61-48.5-97.2-61 38.8-26.7 64.2-71.4 64.2-121.9C403.9
                                  66.4 337.6 0 256 0S108.1 66.4 108.1 147.9c0 50.5 25.5 95.2 64.2 121.9-36.2 12.5-69.4
                                  33.1-97.2 61C26.8 379.1.1 443.4.1 511.7h40c0-110.9 84-202.5 191.7-214.5l-37.9
                                  152.7L256 512l62.1-62.1-37.9-152.7c107.7 12.1 191.7 103.7 191.7
                                  214.5h40c0-68.3-26.7-132.6-75-180.9zM256 455.5l-17.9-17.9 17.9-71.9 17.9 71.9-17.9
                                  17.9zm0-199.6c-59.5 0-107.9-48.4-107.9-107.9 0-59.6 48.4-108 107.9-108s107.9 48.4 107.9
                                  107.9-48.4 108-107.9 108z"/>
                                    </svg>
                                    Клиенты
                                </a>
                            </li>
                            <li className="mainnav__item">
                                <a href="" className="mainnav__link">
                                    <svg className="mainnav__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M452 105.5H345.9v-20c0-33.1-26.9-60-60-60H226c-33.1 0-60 26.9-60 60v20H60c-33.1 0-60 26.9-60 60v261c0 33.1 26.9 60 60 60h392c33.1 0 60-26.9 60-60v-261c0-33.1-26.9-60-60-60zm-245.9-20c0-11 9-20 20-20H286c11 0 20 9 20 20v20h-99.9v-20zm265.9 341c0 11-9 20-20 20H276v-131h-40v131H60c-11 0-20-9-20-20v-151h36V317l60 60 60-60v-41.5h120V317l60 60 60-60v-41.5h36v151zm-356-126v-25h40v25l-20 20-20-20zm240 0v-25h40v25l-20 20-20-20zm116-65H40v-70c0-11 9-20 20-20h392c11 0 20 9 20 20v70z"/>
                                    </svg>
                                    Услуги
                                </a>
                            </li>
                            <li className="mainnav__item" v-if="userGroup === 'chief'">
                                <a href="" to="/users" className="mainnav__link">
                                    <svg className="mainnav__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M393 199.6c25-20.7 40.9-51.9 40.9-86.8C433.8 50.6 383.3 0 321.1 0S208.3 50.6 208.3 112.8c0 8.1.9 16.1 2.5 23.7-6.4-1.1-13-1.7-19.8-1.7-62.2 0-112.8 50.6-112.8 112.8 0 34.9 15.9 66.1 40.9 86.8C49.3 362.8 0 431.4 0 511.3h40c0-83.3 67.8-151 151-151 62.2 0 112.8-50.6 112.8-112.8 0-8.1-.9-16.1-2.5-23.7 6.4 1.1 13 1.7 19.8 1.7 49.1 0 92.7 23.5 120.4 59.9l27.1-30.2c-20.1-24.2-46-43.5-75.6-55.6zM191 320.3c-40.1 0-72.8-32.6-72.8-72.8s32.6-72.8 72.8-72.8 72.8 32.6 72.8 72.8-32.6 72.8-72.8 72.8zm130.1-134.8c-40.1 0-72.8-32.6-72.8-72.8S281 40 321.1 40s72.8 32.6 72.8 72.8c-.1 40.1-32.7 72.7-72.8 72.7z"/>
                                        <path d="M482.2 299.9L343.3 454.6 264.9 380l-27.6 29 108.2 103L512 326.6z"/>
                                    </svg>
                                    Пользователи
                                </a>
                            </li>
                            <li className="mainnav__item">
                                <a href="" className="mainnav__link">
                                    <svg className="mainnav__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M199.9 359.9h68v40h-68v-40zm-74.9-5c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zm276.9-122h-202v40h201.9v-40zm-276.9-5c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zM401.9 113h-202v40h201.9v-40zM125 108c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zM40 451.9V60c0-11 9-20 20-20h391.9c11 0 20 9 20 20v225.9h40V60c0-33.1-26.9-60-60-60H60C26.9 0 0 26.9 0 60v391.9c0 33.1 26.9 60 60 60h230.9v-40H60c-11 0-20-9-20-20zM483.7 512l-39.8-39.8c-13.5 8.1-29.2 12.7-46 12.7-49.6 0-90-40.4-90-90s40.4-90 90-90 90 40.4 90 90c0 18.4-5.5 35.4-15 49.7l39.1 39.1-28.3 28.3zm-85.8-67.1c27.6 0 50-22.4 50-50s-22.4-50-50-50-50 22.4-50 50 22.4 50 50 50z"/>
                                    </svg>
                                    Отчеты
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <Sidebar
                        userGroupValue={this.props.userGroupValue}
                        pageWrapId={'page-wrap'} outerContainerId={'outer-container'}
                    />

                </div>

            </React.Fragment>
        );
    }
}

export default Header;
