import React, {useEffect, useState} from "react";
import Header from "../header/header.jsx";
import Block from "../block/block";
import {USER_ROLES} from "../../const";
import axios from "axios";
import {URLs} from "../../url";
import authHeader from "../../services/auth-header";
import history from "../../history";

function Queue(props) {

    const calculateTimeLeft = () => {

        const stamp = blockData.stamp;
        const getDate = new Date(stamp);
        let minutes = getDate.getMinutes();
        getDate.setMinutes(minutes + blockData.duration);

        const difference = +new Date(getDate) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                minutes: Math.floor((difference / 1000 / 60) % 60) > 9
                    ? Math.floor((difference / 1000 / 60) % 60)
                    : '0' +  Math.floor((difference / 1000 / 60) % 60),

                seconds: Math.floor((difference / 1000) % 60) > 9 ? Math.floor((difference / 1000) % 60)
                    : '0' + Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;

    };


    const [blockData, getBlockDate] = useState([]);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [showBlockForm, setShowBlockForm] = useState(true);

    console.log(blockData.is_dropped);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });


    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, index) => {
        if (timeLeft[interval] === undefined) {
            return;
        }
        timerComponents.push(
           <span key={interval}>{timeLeft[interval]} {" "}</span>

        );
    });

    const stopTimer = (evt) => {
        evt.preventDefault();
        axios.post(`https://new-scroll.telemiks.tv/api/v1/drop_block/`)
            .then(res => console.log(res))
            .catch(err => console.log(err));
        history.go(0)
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main-page main-page--indent">
                {props.userGroupValue === USER_ROLES.manager || props.userGroupValue === USER_ROLES.corrector
                    ? <div className="no-rules">Недостаточно прав для просмотра раздела</div>
                    :
                    <div>
                        <div className="main-page__head-wrapper">
                            <div className="main-page__add">
                                <h1 className="title__h1">Выпуск в эфир</h1>
                            </div>
                        </div>
                        <div className="form__block mb-20">

                            <div>

                            </div>

                            {timerComponents.length && blockData.is_dropped === 0
                                ?
                                <form onSubmit={stopTimer}>
                                    <div className="form__block-wrapper">{timerComponents}
                                        <button type="submit" className="button ml-20"
                                                onClick={() => setShowBlockForm(!showBlockForm)}>
                                           Сбросить таймер
                                        </button>
                                    </div>
                                    <p>Хозяйке на заметку: кнопка <b>"сбросить"</b> сбрасывает только счетчик на этой
                                        странице. Если нужно остановить бегучку, которая уже запущена в эфир - это нужно
                                        делать на титровалке.
                                    </p>
                                </form>

                                :
                                <div>
                                    <Block
                                        getBlockDate={getBlockDate}
                                        setShowBlockForm={setShowBlockForm}
                                    />
                                </div>

                            }

                        </div>




                    </div>
                }

            </div>
        </React.Fragment>
    );
}

export default Queue;

