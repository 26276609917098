import axios from "axios";

const API_URL = "https://new-scroll.telemiks.tv/api/v1/auth/obtain_token/";

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL, {
                username,
                password
            })
            .then(response => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            })

    }

    logout() {
        localStorage.removeItem("user");
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default new AuthService();
