import React, {useEffect, useState} from 'react';
import Header from "../header/header";
import {queryString, URLs} from "../../url";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
import moment from "moment";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {Link} from "react-router-dom";
import {AppRoute} from "../../const";
const api = createAPI();

function ReportBlock(props) {
    const [blocksReport, setIssueReport] = useState([]);

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.REPORTS + props.match.params.date + queryString.TYPE_BLOCK,
            { headers: authHeader() })
            .then(res => {
                console.log(res);
                setIssueReport(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [props.match.params.date]);

    console.log(blocksReport);

    return (
        <div>
            <Header />
            <div className="main-page main-page--indent">
                <div className="main-page__head-wrapper">
                    <div className="main-page__add">
                        <h1 className="title__h1">Блоки за {props.match.params.date}</h1>
                    </div>
                </div>
                <div>
                    <Link className="button button__link profile__button profile__button--mb" to={AppRoute.REPORTS}>
                        Сформировать другой отчет
                    </Link>
                    <Link className="button ml-20 button__link profile__button profile__button--mb"
                          to={`${AppRoute.REPORTS}/${moment(props.match.params.date).format('YYYY-MM-DD')}`}>
                        Вышедшие объявления на: {moment(props.match.params.date).format('YYYY-MM-DD')}
                    </Link>
                </div>
                <div>
                    <Accordion allowMultipleExpanded>
                        {blocksReport.map((item, index) => {
                            return (
                                <AccordionItem key={index}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {moment(item.block.complete_date).format('YYYY-MM-DD HH:mm')}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {item.scroll.map((subitem, index) =>
                                            <div className="tables__data" key={index}>{subitem.text_plain}</div>)}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                </div>
            </div>

        </div>
    );
}

export default ReportBlock;
