import React, {useEffect, useState} from 'react';
import { slide as Menu } from 'react-burger-menu';
import {Link, NavLink} from "react-router-dom"
import {USER_ROLES} from "../../const";
import {URLs} from "../../url";
import authHeader from "../../services/auth-header";
import {createAPI} from "../../api";
const api = createAPI();

export default props => {
    const [userGroup, getUserGroup] = useState(``);

    useEffect(() => {
        api.get(URLs.BASE_URL + URLs.USER_INFO, { headers: authHeader() })
            .then(res => {
                console.log(res);
                getUserGroup(res.data.groups[0]);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const userGroupValue = Object.values(userGroup).join();

    return (
        <Menu>
            {userGroupValue === USER_ROLES.corrector || userGroupValue === USER_ROLES.manager ? '' :
                <NavLink exact activeClassName="active" to={"/"} className="menu-item">
                Объявления
                </NavLink>
            }

            {userGroupValue === USER_ROLES.corrector || userGroupValue === USER_ROLES.manager ? '' :
                <
                    NavLink exact activeClassName="active" to={"/queue"} className="menu-item">
                    Выпуск
                </NavLink>
            }
            {userGroupValue === USER_ROLES.corrector ? '' :
                <NavLink exact activeClassName="active" to={"/reports"} className="menu-item">
                    Отчеты
                </NavLink>
            }


            {userGroupValue === USER_ROLES.manager ? '' :
                <NavLink exact activeClassName="active" to={"/corrects"} className="menu-item">
                    Корректор
                </NavLink>
            }

        </Menu>
    );
};
