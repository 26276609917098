import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/sass/style.scss';
import App from './components/app/App.jsx';



ReactDOM.render(
    <div>
        <App />,
    </div>,
    document.querySelector(`#root`)
);
